export default {
  1: {
    name: 'Abierto',
    cssClass: [
      'bg-red-300'
    ]
  },
  2: {
    name: 'Solicitado por sede',
    cssClass: [
      'bg-blue-600',
      'text-white'
    ]
  },
  3: {
    name: 'Anulado por sede',
    cssClass: [
      'bg-yellow-500',
      'text-white'
    ]
  },
  4: {
    name: 'Pedido en alistamiento',
    cssClass: [
      'bg-green-400',
      'text-white'
    ]
  },
  5: {
    name: 'Rechazado',
    cssClass: [
      'bg-red-600',
      'text-white'
    ]
  },
  6: {
    name: 'Aprovisionando con proveedor',
    cssClass: [
      'bg-yellow-600',
      'text-white'
    ]
  },
  7: {
    name: 'En camino',
    cssClass: [
      'bg-green-600',
      'text-white'
    ]
  },
  8: {
    name: 'Recibido por sede',
    cssClass: [
      'bg-green-800',
      'text-white'
    ]
  },
  9: {
    name: 'Confirmado por sede',
    cssClass: [
      'bg-purple-900',
      'text-white'
    ]
  },
  10: {
    name: 'Pendiente',
    cssClass: [
      'bg-theme-9',
      'text-white'
    ]
  },
  11: {
    name: 'Entregado',
    cssClass: [
      'bg-primary-1',
      'text-white'
    ]
  }
}
