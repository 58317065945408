import { computed, ref } from 'vue'

const _legendStorageName = 'solicitudes.legends.isVisible'
const _localStorage = window.localStorage

const _getLegendState = () => {
  const value = _localStorage.getItem(_legendStorageName)
  if (value) { return JSON.parse(value) }
  _localStorage.setItem(_legendStorageName, false)
  return false
}

const useSolicitudes = () => {
  const _isLegendsVisible = ref(_getLegendState())

  const isLegendsVisible = computed(() => _isLegendsVisible.value)

  const onLegendsToggle = () => {
    _isLegendsVisible.value = !_isLegendsVisible.value
    _localStorage.setItem(_legendStorageName, _isLegendsVisible.value)
  }

  return {
    isLegendsVisible,
    onLegendsToggle
  }
}
export default useSolicitudes
