const permissions = {
  canCrear: 'pharmasan.administrativa.compras-administrativas.solicitudes.creacion',
  canAnular: 'pharmasan.administrativa.compras-administrativas.solicitudes.anulacion',
  canConfirmar: 'pharmasan.administrativa.compras-administrativas.solicitudes.confirmacion',
  canValidarConsolidar: 'pharmasan.administrativa.compras-administrativas.solicitudes.validar-consolidacion',
  canAprovisionarProveedor: 'pharmasan.administrativa.compras-administrativas.solicitudes.aprovisionar-proveedor',
  canGuardar: 'pharmasan.administrativa.compras-administrativas.solicitudes.guardar',
  canEnCamino: 'pharmasan.administrativa.compras-administrativas.solicitudes.en-camino',
  canRechazar: 'pharmasan.administrativa.compras-administrativas.solicitudes.rechazar',
  canRecibirSede: 'pharmasan.administrativa.compras-administrativas.solicitudes.recibir-por-sede',
  canConfirmaRecibido: 'pharmasan.administrativa.compras-administrativas.solicitudes.confirmar-recibido',
  canDuplicar: 'pharmasan.administrativa.compras-administrativas.solicitudes.duplicar',
  canFilterSede: 'pharmasan.administrativa.compras-administrativas.solicitudes.filtrar-sede',
  canFilterArea: 'pharmasan.administrativa.compras-administrativas.solicitudes.filtrar-area',
  canGraficaTendencia: 'pharmasan.administrativa.compras-administrativas.solicitudes.ver-grafica-tendencia',
  canIndicadoresTendencia: 'pharmasan.administrativa.compras-administrativas.solicitudes.ver-indicadores-tendencia',
  canConfirmarEntrega: 'pharmasan.administrativa.compras-administrativas.solicitudes.confirmar-entrega',
  canCantidadAprobada: 'pharmasan.administrativa.compras-administrativas.solicitudes.editar-cantidad-aprobada',
  canCantidadSolicitada: 'pharmasan.administrativa.compras-administrativas.solicitudes.editar-cantidad-solicitada'
}

export default permissions
