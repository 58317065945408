<template>
  <section class="intro-x">
    <div class="w-full mb-2 mt-2 flex items-center">
      <div class="w-1/2 my-auto flex">
        <div title="Ver legendas de estados" class="mr-2 cursor-pointer" @click="onLegendsToggle">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor"
              d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C16.86,4.5 21.22,7.5 23,12C20.61,18.08 13.75,21.06 7.67,18.67C4.62,17.47 2.2,15.06 1,12C2.78,7.5 7.14,4.5 12,4.5M3.18,12C5.56,16.87 11.45,18.89 16.32,16.5C18.28,15.54 19.86,13.96 20.82,12C18.44,7.13 12.55,5.11 7.68,7.5C5.72,8.46 4.14,10.04 3.18,12M9,22H7V24H9V22M13,22H11V24H13V22M17,22H15V24H17V22Z" />
          </svg>
        </div>
        <h1 class="font-bold text-lg">
          Estados de la solicitud
        </h1>
      </div>
      <div v-if="hasCreate" class="w-1/2 flex justify-end justify-end">
        <router-link :to="{name: 'pharmasan.administrativa.compras-administrativas.solicitudes.carga-masiva'}"
          class="btn btn-primary capitalize flex items-center">
          <span class="mr-2">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor"
                d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
            </svg>
          </span>
          <span style="position:relative; top:1px;">Carga masiva </span>
        </router-link>
      </div>
    </div>
    <RequestLegends v-if="isLegendsVisible" />
    <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4 my-3 box p-4">
      <!-- estados -->
      <div class="flex items-center">
        <label for="state" class="w-1/3">Estado</label>
        <select v-model="filter.state" id="state" class="form-control">
          <option value="">--Seleccionar--</option>
          <option class="uppercase" v-for="(row,k) in getRequestDomain" :key="k" :value="k">
            {{row.name}}
          </option>
        </select>
      </div>
      <!-- sedes -->
      <div v-if="$can(getCan.canFilterSede)" class="mb-1 flex items-center">
        <label for="branchOfficeId" class="w-1/3">Ub. <br> Sede</label>
        <select name="branchOfficeId" v-model="filter.branchOfficeId" class="form-control capitalize">
          <option value="">Seleccionar</option>
          <option v-for="(row,k) in getBranchOffice" :key="k" :value="row.id" class="capitalize">
            {{row.name}}
          </option>
        </select>
      </div>
      <!-- Areas -->
      <div v-if="$can(getCan.canFilterArea)" class="mb-1 flex items-center">
        <label for="areaId" class="w-1/3">Area</label>
        <select name="areaId" v-model="filter.areaId" class="form-control capitalize">
          <option value="">Seleccionar</option>
          <option v-for="(row,k) in getAreas" :key="k" :value="row.id" class="capitalize">
            {{row.name}}
          </option>
        </select>
      </div>
      <!-- tipo -->
      <div class="mb-1 flex items-center">
        <label for="tipoSolicitud" class="w-1/3">Tipo Solicitud</label>
        <!-- @change="selectTipoSolicitud" -->
        <select name="type" v-model="filter.type" class="form-control capitalize">
          <option value="">Seleccionar</option>
          <option :value="1" class="capitalize">
            Mensual
          </option>
          <option :value="2" class="capitalize">
            Extra
          </option>
        </select>
      </div>
      <!-- mensual -->
      <div class="mb-1 flex items-center">
        <label for="mesAno" class="w-1/3">Mes y año</label>
        <input type="month" class="form-control" v-model="filter.monthYear" autocomplete="off">
      </div>
      <!-- botones -->
      <div class="w-full flex items-center ">
        <Button label="Buscar" @click="onFilter" />
        <Button label="Refrescar" class="p-button-outlined ml-2" @click="fetchRequests" />
        <Button label="Limpiar" class="p-button-outlined p-button-secondary ml-2" @click="onFilterClear" />
      </div>
      <div class="col-span-2 text-right">
        <Button label="Reporte" class="p-button-success" @click="handleExcelDownloadConsolidado"
          title="Descargar excel Consolidado" />
        <Button label="Agregar" class="p-button-outlined p-button-success ml-2" v-if="$can(getCan.canCrear)"
          @click="$router.push({name: 'pharmasan.administrativa.compras-administrativas.solicitudes.formulario'})" />
      </div>
    </div>

    <div class="w-full">
      <DataTable @sort="orderFiled" :value="getRequest" class="p-datatable-sm">
        <Column header="Acciones" headerStyle="width:200px; text-align:center;">
          <template #body="{data}">
            <router-link :to="{name:'pharmasan.administrativa.compras-administrativas.solicitudes.detail', params:{ id: data.id}}"
              class="btn btn-primary p-1" title="Ver pedido">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
              </svg>
            </router-link>
            <router-link :to="{name: 'pharmasan.administrativa.compras-administrativas.solicitudes.historial', params: {id: data.id}}"
              class="btn btn-warning ml-1 p-1" title="Historico">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M15,4A8,8 0 0,1 23,12A8,8 0 0,1 15,20A8,8 0 0,1 7,12A8,8 0 0,1 15,4M15,6A6,6 0 0,0 9,12A6,6 0 0,0 15,18A6,6 0 0,0 21,12A6,6 0 0,0 15,6M14,8H15.5V11.78L17.83,14.11L16.77,15.17L14,12.4V8M2,18A1,1 0 0,1 1,17A1,1 0 0,1 2,16H5.83C6.14,16.71 6.54,17.38 7,18H2M3,13A1,1 0 0,1 2,12A1,1 0 0,1 3,11H5.05L5,12L5.05,13H3M4,8A1,1 0 0,1 3,7A1,1 0 0,1 4,6H7C6.54,6.62 6.14,7.29 5.83,8H4Z" />
              </svg>
            </router-link>
            <button type="button" class="btn btn-success p-1 ml-1" title="Descargar excel"
              @click="handleExcelDownload(data.id)">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M17,13L12,18L7,13H10V9H14V13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
              </svg>
            </button>
            <template v-if="$can(getCan.canDuplicar)">
              <router-link v-if="data.stateLast === 5"
                :to="{name:'pharmasan.administrativa.compras-administrativas.solicitudes.formulario', params:{ id: data.id}}" class="btn btn-danger ml-1 p-1"
                title="Duplicar Solicitud">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor"
                    d="M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z" />
                </svg>
              </router-link>
            </template>
          </template>
        </Column>
        <Column header="No." field="consecutive" sortable headerStyle="width:90px;" />
        <Column header="Fecha" field="date" sortable headerStyle="width:120px;" />
        <Column header="Estado" field="stateLast" headerStyle="min-width: 150px;" sortable>
          <template #body="{data}">
            <span class="p-1 rounded-sm" :class="getRequestDomain[data.stateLast].cssClass">
              {{getRequestDomain[data.stateLast].name}}
            </span>
          </template>
        </Column>
        <Column header="Tipo" field="type" headerStyle="width: 100px;" sortable>
          <template #body="{data}">
            <span class="p-1 rounded-sm">
              {{data.type===1?'Mensual':'Extra'}}
            </span>
          </template>
        </Column>
        <Column header="Ub. despacho" field="branchOffice" sortable headerStyle="width:150px;" />
        <Column header="Ub. Area" field="area" sortable headerStyle="width:150px;" />
        <Column header="Solicitado Por." field="requestUser" sortable headerStyle="width:150px;" />
      </DataTable>
    </div>
    <div class="w-full">
      <Paginator :rows="getPaginate.perPage" :totalRecords="getPaginate.total" :first="getPaginate.first"
        :rowsPerPageOptions="[10,20,30]" @page="onPage($event)" />
    </div>
  </section>
</template>

<script>
import requestsListar from '../../../../services/requestsListar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Paginator from 'primevue/paginator'
import { computed, defineAsyncComponent, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import requestDomain from '../../../../../../../domains/requestDomain'
import brancheOfficesListar from '../../../../services/brancheOfficesListar'
import areasListar from '../../../../services/areasListar'
import can from '../can'
import useSolicitudes from './useSolicitudes'
import useSolicitudesForm from '../form/useSolicitudesForm'

export default {
  name: 'SolicitudLista',
  components: {
    DataTable,
    Column,
    Paginator,
    RequestLegends: defineAsyncComponent(() => import('../components/legends'))
  },
  setup () {
    const getCan = computed(() => can)
    const route = useRoute()
    const router = useRouter()

    const { fetchParametros, getParametros } = useSolicitudesForm()

    const {
      isLegendsVisible,
      onLegendsToggle
    } = useSolicitudes()
    const filter = ref({
      state: '',
      branchOfficeId: '',
      areaId: '',
      type: '',
      monthYear: ''
    })
    const request = reactive({
      total: 0,
      per_page: 0,
      data: []
    })
    const getRequest = computed(() => {
      return request.data
    })
    const getPaginate = computed(() => {
      return {
        perPage: request.per_page,
        total: request.total,
        first: +route.query.first || 0
      }
    })
    const branchOffice = ref({
      data: []
    })
    const getBranchOffice = computed(() => {
      return branchOffice.value.data
    })

    const onPage = (row) => {
      const page = row.page + 1
      const paginate = row.rows
      const queryParams = { ...route.query }
      const first = row.first
      router.push({ query: { ...queryParams, page, first, paginate } })
    }
    const fetchRequests = () => {
      const queryParams = { ...route.query }
      queryParams.page = queryParams.page ? queryParams.page : 1
      queryParams.paginate = queryParams.paginate ? queryParams.paginate : 10
      return requestsListar(queryParams).then(({ status, data }) => {
        if (status !== 200) return false
        for (const [key, value] of Object.entries(data)) {
          request[key] = value
        }
      }).catch(err => {
        console.error(err.message)
      })
    }
    const handleExcelDownload = (requestsId) => {
      window.open(`${process.env.VUE_APP_MICROSERVICE_API_SHOPS}/requests/${requestsId}/export-template-xls`, '_blank')
    }
    const handleExcelDownloadConsolidado = (requestsId) => {
      window.open(`${process.env.VUE_APP_MICROSERVICE_API_SHOPS}/requests/consolidado/export-template-xls`, '_blank')
    }
    const getRequestDomain = computed(() => {
      return requestDomain
    })
    // eslint-disable-next-line no-unused-vars
    const _routerPush = (obj = {}) => {
      const queryParams = route.query
      router.push({ query: { ...queryParams, ...obj } })
    }
    const onStateChange = (evt) => {
    }
    const onFilter = () => {
      _routerPush({ ...filter.value })
    }

    const fetcBranchOffices = () => {
      return brancheOfficesListar().then(({ data }) => {
        branchOffice.value.data = data
        return data
      }).catch(err => {
        console.error(err.message)
      })
    }

    const onFilterClear = () => {
      for (const key in filter.value) {
        filter.value[key] = ''
      }
    }

    const areas = ref({
      data: []
    })
    const getAreas = computed(() => areas.value.data)
    const fetchAreas = () => {
      return areasListar().then(({ data }) => {
        areas.value.data = data
        return data
      }).catch(err => {
        console.error(err.message)
      })
    }

    const filterFill = () => {
      const { state, branchOfficeId, areaId, type, monthYear } = route.query
      filter.value.state = state || ''
      filter.value.branchOfficeId = branchOfficeId || ''
      filter.value.areaId = areaId || ''
      filter.value.type = type || ''
      filter.value.monthYear = monthYear || ''
    }

    const hasCreate = computed(() => {
      const diaHoy = new Date().getDate()
      if (diaHoy >= getParametros.value.diaApertura && diaHoy <= getParametros.value.diaCierre) {
        return true
      }
      return false
    })

    const actionForm = computed(() => {
      return `${process.env.VUE_APP_MICROSERVICE_API_JASPERREPORT}/encrypted-url`
    })
    onMounted(() => {
      fetchParametros()
      filterFill()
      fetchRequests()
      fetcBranchOffices()
      fetchAreas()
    })
    watch(() => route.query, (value) => {
      fetchRequests()
    })
    const orderFiled = ({ sortField, sortOrder }) => {
      _routerPush({ order: `${sortField},${sortOrder}` })
    }
    return {
      hasCreate,
      filter,
      getRequest,
      getPaginate,
      getRequestDomain,
      getBranchOffice,
      getAreas,
      getCan,
      isLegendsVisible,
      onPage,
      handleExcelDownload,
      onStateChange,
      onFilter,
      onFilterClear,
      onLegendsToggle,
      fetchRequests,
      actionForm,
      handleExcelDownloadConsolidado,
      orderFiled
    }
  }
}
</script>
